

























  /*

  This builds on <ModalDialog /> adding styles and slots for a "regular" dialog
  with a header with a close button and contents below. This should usually be
  used when a dialog is needed, instead of directly using <ModalDialog />.

  */

  import Vue from 'vue';
  import Icon from './Icon/Icon.vue';
  import ModalDialog from './ModalDialog.vue';

  export default Vue.extend({
    components: { Icon, ModalDialog },
    props: {
      closable: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
  });
